import React, { useState } from "react";
import OnboardingModal from "./OnBoardingModal";
import HorizontalLogo from "./HorizontalLogo";
import { useNavigate } from "react-router";
import { logEvent, onboardNewUser } from "../api/onboard";
import CompleteType from "../utils/CompleteType";

function OnboardingContainer(props) {

  const [curStep, setStep] = useState(0);
  const [disableNext, setDisableNext] = useState("true");
  const [complete, setComplete] = useState(false)
  const [surveyData, setSurveyData] = useState(null);
  const navigate = useNavigate();

  const sendOnboardingDataToServer = () => {

    let email = localStorage.getItem("emailSignup")
    let domain = email.substring(email.indexOf("@") + 1)

    let userName = localStorage.getItem("userName")
    let customerName = localStorage.getItem("customerName")

    if (userName == null) userName = email
    if (customerName == null) customerName = domain + " Lab"

    logEvent("onboarding-request", "Create new account request")

    onboardNewUser(email, userName, customerName, surveyData, "12345", (result) => {
      if (result?.token) {
        localStorage.setItem("token", result.token)
      }
      if(result?.isWaitList == true) {
        setComplete(CompleteType.WAITLIST)
      } else {
        setComplete(CompleteType.ACCOUNT_CREATED)
      }
    })

  }

  const checkNextStep = () => {
    const nextStep = curStep >= 4 ? 4 : curStep + 1
    setStep(nextStep);
    if(nextStep == 4) {
      sendOnboardingDataToServer()              
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <HorizontalLogo size={"small"} />
      <OnboardingModal title="title" subtitle="subtitle" setSurveyData={setSurveyData} step={curStep} disableNext={disableNext} setDisableNext={setDisableNext} complete={complete} setComplete={setComplete}/>
      <div className="nav-btn">
        { curStep == 4 ? <></> : <>
        <button
          onClick={() => {
            if (curStep == 0){ 
              navigate("/")
            }
            setStep(curStep <= 0 ? 0 : curStep - 1);
            setComplete(null)
          }}
          style={{
            backgroundColor: "transparent",
            borderColor: "#565656",
            borderRadius: "4px",
            border: "1px solid #565656",
            padding: "8px 18px",
            color: "#565656",
          }}
        >
          Back
        </button>
        <button
         disabled={disableNext}
          onClick={checkNextStep}
          style={{
            backgroundColor: disableNext ? "transparent" : "#6A35FF",
            borderColor: "#565656",
            borderRadius: "4px",
            border: "1px solid #565656",
            padding: "8px 18px",
            color: disableNext ? "grey" : "white",
          }}
        >
          Next
        </button>{" "}
        </>}
      </div>
    </div>

  );
}

export default OnboardingContainer;
